/* 
 * Apply to ion-modal for full screen
 * Usage: <IonModal cssClass="fullscreen-modal">
 */
.fullscreen-modal .modal-wrapper {
  --width: 100%;
  --height: 100%;
}

.sc-ion-popover-md {
  --width: auto;
}
.popover-content.sc-ion-popover-ios {
  --width: auto;
}
