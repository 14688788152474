.ql-container {
  font-size: 0.875rem !important;
  font-family: inherit !important;
}

.ql-container.ql-snow {
  border: none !important;
}

.ql-editor {
  line-height: 1.58;
  padding-left: calc(var(--ion-padding, 16px) * 0.5);
}

.ql-editor.ql-blank::before {
  color: var(--ion-color-medium, #92949c) !important;
  font-style: initial !important;
  left: calc(var(--ion-padding, 16px) * 0.5) !important;
}

.ql-mention-list-container {
  border: 1px solid
    var(
      --ion-item-border-color,
      var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13)))
    );
  border-radius: 4px;
  background-color: var(--ion-background-color);
  box-shadow: 0 2px 12px 0 rgba(30, 30, 30, 0.08);
  z-index: 10;
  overflow: auto;
  min-width: 270px;
  max-width: 540px;
  max-height: 220px;
}

.ql-mention-list {
  list-style: none;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: calc(var(--ion-padding, 16px) * 0.5) 0;
}

.ql-mention-list-item {
  cursor: pointer;
  line-height: 1.5;
  font-size: 0.875rem;
  padding: calc(var(--ion-padding, 16px) * 0.5) var(--ion-padding, 16px);
  vertical-align: middle;
}

.ql-mention-list-item.selected {
  background-color: var(--luke-color-mention-list);
  text-decoration: none;
}

.mention {
  height: 24px;
  width: 65px;
  border-radius: 6px;
  color: var(--luke-color-mention);
  background-color: var(--luke-color-mention-background);
  padding: 2px 0;
  margin-right: 2px;
}

.mention > span {
  margin: 0 3px;
}
