/** This is where overrides across the app are  */

:root {
  --amplify-font-family: inherit;
  /* Headers */
  --amplify-text-md-sub: 1.2rem;

  --ion-backdrop-opacity: 0.6;
}

html.md {
  font-size: 16px;
}

@media screen and (min-width: 768px) {
  .md ion-modal {
    --width: calc(100% - 120px);
    --height: calc(100% - (120px + var(--ion-safe-area-top) + var(--ion-safe-area-bottom)));
    --max-width: 720px;
    --max-height: 1000px;
  }
}

.header-md ion-toolbar:last-of-type {
  --border-width: 0 0 0.55px;
}
.header-md::after {
  background-image: none;
}
.footer-md::before {
  background-image: none;
}

ion-toolbar ion-button,
ion-toolbar ion-back-button,
ion-toolbar ion-menu-button {
  --ion-toolbar-color: var(--ion-color-primary, inherit);
}

ion-chip ion-label {
  line-height: 1.2;
}

ion-card {
  margin: 11px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}

ion-skeleton-text {
  /* Consistent height with actual text elements when wrapped in `p` tag */
  height: 0.75rem;
}

.list-md,
.list-ios {
  background: var(--ion-background-color, #fff);
}

ion-item {
  --inner-padding-end: var(--ion-margin, 16px);
}

ion-item-divider {
  --padding-end: var(--ion-margin, 16px);
  --padding-top: 4px;
  --padding-bottom: 4px;
  --background: var(--ion-item-background, #ffffff);
}

ion-searchbar.sc-ion-searchbar-md-h {
  padding-bottom: 11px;
  --box-shadow: none;
  --background: var(--ion-color-light);
}
.md ion-searchbar.sc-ion-searchbar-md-h {
  padding-inline-start: var(--ion-margin, 16px);
  padding-inline-end: var(--ion-margin, 16px);
}

.action-sheet-title.sc-ion-action-sheet-md {
  height: auto;
  line-height: 1.5;
}

.alert-title.sc-ion-alert-md {
  font-size: 1.2rem;
  font-weight: 400;
}

.alert-message.sc-ion-alert-md {
  font-size: 0.9375rem;
  line-height: 1.5;
}

ion-segment-button.ios {
  --indicator-color: var(--ion-item-background);
  font-weight: 400;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'].native-input {
  appearance: textfield;
}
