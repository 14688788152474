ion-menu.main-menu ion-content {
  --background: var(--ion-toolbar-background, var(--ion-background-color, #fff));
}

ion-menu.md.main-menu ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.main-menu ion-list,
ion-menu.main-menu ion-item {
  --background: transparent;
  background: transparent;
}

ion-menu.md.main-menu ion-select {
  padding-left: 0;
}

ion-menu.md.main-menu ion-list-header,
ion-menu.md.main-menu ion-note {
  padding-left: 10px;
}

ion-menu.main-menu ion-list#nav-list {
  padding-bottom: 0;
}

ion-menu.md.main-menu ion-list#nav-list ion-list-header {
  font-size: 20px;
  font-weight: 400;
  min-height: 20px;
}

ion-menu.md.main-menu ion-list#labels-list ion-list-header {
  font-size: 16px;
  margin-bottom: 18px;
  color: #757575;
  min-height: 26px;
}

ion-menu.md.main-menu ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md.main-menu ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md.main-menu ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md.main-menu ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios.main-menu ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios.main-menu ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios.main-menu ion-note {
  line-height: 24px;
}

ion-menu.ios.main-menu ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios.main-menu ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios.main-menu ion-item .selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios.main-menu ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios.main-menu ion-list-header,
ion-menu.ios.main-menu ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios.main-menu ion-select {
  padding-left: 0;
}

ion-item.selected {
  --color: var(--ion-color-primary);
}
