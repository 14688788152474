.separator {
  width: 1px;
  height: 16px;
  margin-left: calc((var(--ion-margin, 16px) * 2) + (0.625rem * 0.5) - 0.5px);
  opacity: 0.5;
  background: var(--ion-color-primary);
}

.yearSeparator {
  width: 1px;
  height: 52px;
  margin-left: calc((var(--ion-margin, 16px) * 2) + (0.625rem * 0.5) - 0.5px);
  opacity: 0.5;
  background: var(--ion-color-primary);
}

.wrap-2-lines {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

@media screen and (max-width: 576px) {
  .yearSeparator,
  .separator {
    margin-left: calc(var(--ion-margin, 16px) + (0.5rem * 0.5) - 0.5px);
  }
}
