/* Ionic Variables and Theming. For more info, please see:

/** Ionic CSS Variables **/
:root {
  --ion-background-color: #f3f4f6;
  --ion-background-color-rgb: 255, 0, 0;

  --ion-toolbar-background: #f8f9fc;
  --ion-toolbar-background-rgb: 248, 249, 252;

  --ion-toolbar-color: #000000;
  --ion-toolbar-color-rgb: 0, 0, 0;

  --ion-item-background: #ffffff;
  --ion-item-background-rgb: 255, 255, 255;

  --ion-text-color: #000000;
  --ion-text-color-rgb: 0, 0, 0;

  /** primary **/
  --ion-color-primary: #477876;
  --ion-color-primary-rgb: 71, 120, 118;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #194c4a;
  --ion-color-primary-tint: #75a7a5;

  /** secondary **/
  --ion-color-secondary: #f3a259;
  --ion-color-secondary-rgb: 243, 162, 89;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #d68f4e;
  --ion-color-secondary-tint: #f1cca8;

  /** tertiary **/
  --ion-color-tertiary: #f8c8b9;
  --ion-color-tertiary-rgb: 248, 200, 185;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #6c220b;
  --ion-color-tertiary-tint: #f39e84;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #e8eaee;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** highlight **/
  --ion-color-highlight: #18ffff;
  --ion-color-highlight-rgb: 24, 255, 255;
  --ion-color-highlight-contrast: #00999b;
  --ion-color-highlight-contrast-rgb: 0, 153, 155;
  --ion-color-highlight-shade: #00cbcc;
  --ion-color-highlight-tint: #76ffff;

  /** mention **/
  --luke-color-mention: #298edb;
  --luke-color-mention-background: rgba(29, 155, 201, 0.08);
  --luke-color-mention-list: #e8eaee;

  /** toast **/
  --ion-color-toast: #5e646e;
  --ion-color-toast-rgb: 94, 100, 110;
  --ion-color-toast-contrast: #ffffff;
  --ion-color-toast-contrast-rgb: 255, 255, 255;
  --ion-color-toast-shade: #42464d;
  --ion-color-toast-tint: #868c98;

  --amplify-background-color: var(--ion-background-color);
  --amplify-primary-color: var(--ion-color-primary);
  --amplify-primary-tint: var(--ion-color-primary-tint);
  --amplify-primary-shade: var(--ion-color-primary-shade);

  --amplify-secondary-color: var(--ion-text-color);

  --amplify-secondary-contrast: var(--ion-item-background);

  --amplify-grey: var(--ion-color-medium);
  --amplify-light-grey: var(
    --ion-item-border-color,
    var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13)))
  );

  /** assets **/
  --luke-asset-item-background: rgba(245, 227, 69, 0.1);
  --luke-asset-item-background-contrast: #000000;
  --luke-asset-item-background-contrast-rgb: 0, 0, 0;
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  :root {
    --ion-background-color: #1e1e1e;
    --ion-background-color-rgb: 30, 30, 30;

    --ion-toolbar-background: #1f1f1f;
    --ion-toolbar-background-rgb: 31, 31, 31;

    --ion-toolbar-color: #e6e6e6;
    --ion-toolbar-color-rgb: 230, 230, 230;

    --ion-item-background: #191919;
    --ion-item-background-rgb: 25, 25, 25;

    --ion-text-color: #e6e6e6;
    --ion-text-color-rgb: 230, 230, 230;

    --ion-border-color: #292929;
    --ion-border-color-rgb: 41, 41, 41;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-color-primary: #479676;
    --ion-color-primary-rgb: 71, 150, 118;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #10674a;
    --ion-color-primary-tint: #78c7a5;

    --ion-color-secondary: #fa7551;
    --ion-color-secondary-rgb: 250, 117, 81;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #831f04;
    --ion-color-secondary-tint: #4b3835;

    --ion-color-tertiary: #f19173;
    --ion-color-tertiary-rgb: 248, 200, 185;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #6e220b;
    --ion-color-tertiary-tint: #f39f85;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47, 223, 117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255, 213, 52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255, 73, 97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #2f3237;
    --ion-color-light-tint: #1c1e21;

    --ion-color-highlight: #18ffff;
    --ion-color-highlight-rgb: 24, 255, 255;
    --ion-color-highlight-contrast: #00999b;
    --ion-color-highlight-contrast-rgb: 0, 153, 155;
    --ion-color-highlight-shade: #00cbcc;
    --ion-color-highlight-tint: #76ffff;

    /** mention **/
    --luke-color-mention: #328fd6;
    --luke-color-mention-background: rgba(29, 155, 201, 0.08);
    --luke-color-mention-list: #222428;

    /** toast **/
    --ion-color-toast: #9fa0a8;
    --ion-color-toast-rgb: 159, 160, 168;
    --ion-color-toast-contrast: #000000;
    --ion-color-toast-contrast-rgb: 0, 0, 0;
    --ion-color-toast-shade: #a9aab1;
    --ion-color-toast-tint: #7e808b;

    /** assets **/
    --luke-asset-item-background: #000000;
    --luke-asset-item-background-rgb: 0, 0, 0;
  }
}

.ion-color-highlight {
  --ion-color-base: var(--ion-color-highlight);
  --ion-color-base-rgb: var(--ion-color-highlight-rgb);
  --ion-color-contrast: var(--ion-color-highlight-contrast);
  --ion-color-contrast-rgb: var(--ion-color-highlight-contrast-rgb);
  --ion-color-shade: var(--ion-color-highlight-shade);
  --ion-color-tint: var(--ion-color-highlight-tint);
}

.ion-color-toast {
  --ion-color-base: var(--ion-color-toast);
  --ion-color-base-rgb: var(--ion-color-toast-rgb);
  --ion-color-contrast: var(--ion-color-toast-contrast);
  --ion-color-contrast-rgb: var(--ion-color-toast-contrast-rgb);
  --ion-color-shade: var(--ion-color-toast-shade);
  --ion-color-tint: var(--ion-color-toast-tint);
}
